import {Elm} from '../elm/Main.elm'

export function initElm(backendHost, docGeneratorUrl) {
    const getRandomInts = (n) => {
        const randInts = new Uint32Array(n);
        crypto.getRandomValues(randInts);
        return Array.from(randInts);
    };

    // For a UUID, we need at least 128 bits of randomness.
    // This means we need to seed our generator with at least 4 32-bit ints.
    // We get 5 here, since the Pcg.Extended generator performs slightly faster if our extension array
    // has a size that is a power of two (4 here).
    const randInts = getRandomInts(5);

    function chooseBackHost(url) {
        let urls = url.split('%')
        const ts = urls.find(a => {
            const tld = window.location.host.split('.').reverse().slice(0, 2).reverse().join('.')
            return a.indexOf(tld) > 1
        })
        return ts ? ts : (urls.length > 1 ? urls[0] : url)
    }
    
    const flags = {
        backHost: chooseBackHost(backendHost),
        docGeneratorHost: chooseBackHost(docGeneratorUrl),
        seed: randInts[0],
        seedExtension: randInts.slice(1),
    }

    var app = Elm.Main.init({
        flags: flags
    });

    /** gère le click sur les liens du sous module, pour les rendre via pushUrl et non load (évite rechargement page) */
    document.addEventListener('click', function (e) {
        const node = targetOrParentHasHref(e.target)
        const href = node && node.getAttribute('href')
        if (href
            && (/^\/.+/.test(href)
                && !/^\/static/.test(href)
                && node.getAttribute('target') !== '_blank')
                && noeIsInSubApp(node)
        ) {
            e.preventDefault()
            app.ports.listenLinkClickedInSubModule.send(href)
        }
    });

    var previousCallback = null

    app.ports.traceThis.subscribe(s => console.warn(s))

    app.ports.unloadSubModule.subscribe(_ => {
        if (previousCallback) {
            previousCallback()
            previousCallback = null;
        }
    })

    app.ports.loadSubModule.subscribe(({route, moduleName, urlToJsFile, flags}) => {
        if (!(window && window[moduleName] && window[moduleName].init)) {
            const subJs = document.createElement("script")
            subJs.setAttribute('src', urlToJsFile)
            document.querySelector('head').appendChild(subJs)

            subJs.addEventListener('load', () => {
                handleElmSubApp(app, route, moduleName, flags, previousCallback)
                    .then(c => previousCallback = c)
            }, true)
        } else {
            handleElmSubApp(app, route, moduleName, flags, previousCallback)
                .then(c => previousCallback = c)
        }
    })

    app.ports.askActiveUserProcessUid.subscribe(() => {
        app.ports.receiveActiveUserProcessUid.send(crypto.randomUUID())
    })

    function findSubModule() {
        const contentSelector = '.subAppContainer'
        return document.querySelector(contentSelector)
    }

    function handleElmSubApp(parentApp, route, moduleName, flags, unsubscribeCallBack) {
        return new Promise((resolve) => {
            const subNode = findSubModule()
            if (subNode) {
                resolve(subNode)
            } else { // sometimes subNode is null to let's poll
                const myInterval = setInterval(() => {
                    const subNode = findSubModule()
                    if (subNode) {
                        clearInterval(myInterval)
                        resolve(subNode)
                    }
                }, 0)
            }
        }).then(subAppNode => {
            var subApp = window[moduleName].init(backendHost, docGeneratorUrl, subAppNode, flags)

            function sendLinkClickedFromParentSubscribe({url}) {
                subApp && subApp.ports && subApp.ports.linkClickedFromParentApp.send(url)
            }
            function displayErrorSubscribe(errors) {
                parentApp.ports.showError.send(errors)
            }
            function loadingEventSubscribe(event) {
                parentApp.ports.loadingEvent.send(event)
            }
            function navigateSubscribe(path) {
                parentApp.ports.listenLinkClickedInSubModule.send(route + path)
            }

            function unsubscribe() {
                subApp.unsubscribe && subApp.unsubscribe()

                parentApp.ports.sendLinkClickedFromParentApp.unsubscribe(sendLinkClickedFromParentSubscribe)
                if (subApp.ports) {
                    return
                }
                subApp.ports.navigate && subApp.ports.navigate.unsubscribe(navigateSubscribe)
                subApp.ports.displayError && subApp.ports.displayError.unsubscribe(displayErrorSubscribe)
                subApp.ports.loadingEvent && subApp.ports.loadingEvent.unsubscribe(loadingEventSubscribe)
            }

            unsubscribeCallBack && unsubscribeCallBack()

            if (!subApp.ports) {
                return () => {}
            }

            parentApp.ports.sendLinkClickedFromParentApp.subscribe(sendLinkClickedFromParentSubscribe)

            subApp.ports.navigate && subApp.ports.navigate.subscribe(navigateSubscribe)

            subApp.ports.displayError && subApp.ports.displayError.subscribe(displayErrorSubscribe)

            subApp.ports.loadingEvent && subApp.ports.loadingEvent.subscribe(loadingEventSubscribe)

            return Promise.resolve(unsubscribe)
        })
    }

    /** ne réagir qu'au clique sur les liens <a href .../> */
    function targetOrParentHasHref(node) {
        if (!node || node === document.documentElement) {
            return false
        }
        if (node.hasAttribute('href') && node.nodeName.toLocaleLowerCase() === 'a') {
            return node
        }
        return targetOrParentHasHref(node.parentNode)
    }
    function noeIsInSubApp(node) {
        if (!node || node === document.documentElement) {
            return false
        }
        if (node.classList.contains('subApp')) {
            return true
        }
        return noeIsInSubApp(node.parentNode)
    }

    return app
}