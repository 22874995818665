port module Main exposing (main)

import Browser exposing (Document, UrlRequest(..))
import Components.ActiveUser.Update as ActiveUserUpdate
import Components.Loading.Update as LoadingUpdate
import Html.Styled exposing (Html, toUnstyled)
import List exposing (singleton)
import Main.MainModel exposing (Model)
import Main.MainUpdate as MainUpdate exposing (Msg(..), init, update)
import Main.MainView as MainView
import Url


main = Browser.application
    { init = init
    , view = MainView.view >> toUnstyled >> singleton >> Document "IESM"
    , update = update
    , subscriptions = subscriptions
    , onUrlRequest = LinkClicked
    , onUrlChange = UrlChanged
    }

subscriptions: Model -> Sub Msg
subscriptions model = Sub.batch
    [ showError Fail
    , listenLinkClickedInSubModule (\rawUrl ->
        let baseUrl = initBaseUrl model.url
            buildedUrl = ({ baseUrl| path = rawUrl } |> Url.toString |> Url.fromString)
            clickLink url = LinkClicked <| Internal url
        in buildedUrl |> Maybe.map clickLink |> Maybe.withDefault (Fail ["Url malformée: ", rawUrl])
    )
    , loadingEvent buildLoadingEvent |> Sub.map (Maybe.map LoadingAction) |> Sub.map (Maybe.withDefault NoOp)
    , MainUpdate.subscriptions model
    , ActiveUserUpdate.subscriptions |> Sub.map ActiveUserComponentAction
    ]

initBaseUrl url = { url| query = Nothing, fragment = Nothing }

buildLoadingEvent: String -> Maybe LoadingUpdate.Msg
buildLoadingEvent raw = case raw of
    "Start" -> Just LoadingUpdate.FetchStart
    "End" -> Just LoadingUpdate.FetchEnd
    _ -> Nothing

port showError: (List String -> msg) -> Sub msg
port listenLinkClickedInSubModule: (String -> msg) -> Sub msg
port loadingEvent: (String -> msg) -> Sub msg